.top-container {
    background: linear-gradient(90deg, #e7e7e7, #FDFDFD);
}

.aboutapp-container {
    background: #ffffff;
}

.aboutapp-container svg,
.howitworks-container svg {
    margin-bottom: 12px;
}

.appDescriptionBox {
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #5DB075;
    text-align: center;
    padding: 15px;
    color: #3F3F3F;
    max-width: 375px;
}

.howitworks-container {
    background: #ffffff;
}

.contactus-container {
    padding-bottom: 50px;
    background: #ffffff;
}

.contactus-card {
    background-color: #EDEDED;
    border-radius: 10px;
    text-align: center;
    padding: 15px;
    color: #686868;
}

.footer {
    background-color: #EDEDED;
  }

  .no-decoration {
    text-decoration: none !important;
  }